import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/cost-capitalization-meta.png'
import tag from '../../assets/images/tags/cost-capitalization-tag.svg'
import FAQ from '../../components/CostCapFAQ'
import Layout from '../../components/Layout'
import MainDemoRequestCTA from '../../components/MainDemoRequestCTA'
import CTABlock from '../../components/blocks/CTABlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import IllustrationBlock from '../../components/blocks/IllustrationBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import MosaicBlock from '../../components/blocks/MosaicBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import { responsiveScale } from '../../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/backgrounds/swarmia-metrics.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    lead: file(
      relativePath: { eq: "images/product/software-capitalization/lead.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/mosaic/work-log-by-author.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/mosaic/software-capitalization-sheet.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    overview: file(relativePath: { eq: "images/enterprise/overview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    integrations: file(
      relativePath: { eq: "images/enterprise/integrations.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
  }
`

const CostCapPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Flexible software cost capitalization reporting"
      variant="dark"
      isNew
      description="Get the exact data you need to create customizable, audit-ready software capitalization reports."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title={<>Flexible software cost capitalization reporting</>}
        content={
          <>
            Get the exact data you need to create customizable,
            audit&#8209;ready software capitalization reports.
          </>
        }
        backgroundImage={getImage(data.backgroundImage)!}
        customCta={<MainDemoRequestCTA skipToCalendar="otto" />}
      />
      <LeadBlock
        heading="The simple way to report on the cost of software development"
        content={
          <>
            Track capitalizable software development work based on flexible
            rules and blend with cost data to create audit-ready reports.
          </>
        }
      />
      <Box>
        <IllustrationBlock
          paddingTop={responsiveScale(32)}
          paddingBottom={responsiveScale(32)}
          image={getImage(data.lead)!}
          noPlaceHolder
          rounded
          shadow
        />
      </Box>
      <QuoteBlock person="christian" />
      <LeadBlock
        heading="Why modern software organizations choose Swarmia"
        content="Swarmia makes cost estimation and capitalization reporting flexible for finance teams, painless for engineers, and ready for auditors."
      />
      <MosaicBlock
        title={
          <>
            It all starts with
            <br /> high-quality data
          </>
        }
        content="Swarmia automatically captures the work software engineers do, which allows you to create custom rules for software cost capitalization."
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title={<>No overhead for engineering or finance teams</>}
        content="You can export the cost capitalization reports from Swarmia monthly or annually — all it takes is one click."
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
      />
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      >
        <FAQ />
      </Box>
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock
          title="Start creating audit-ready cost capitalization reports today"
          customCta={<MainDemoRequestCTA skipToCalendar="otto" />}
        />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/balancing-engineering-investments/',
            '/blog/measuring-software-development-productivity/',
            '/blog/when-to-start-a-platform-team/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default CostCapPage
